import {createTRPCClient, httpBatchLink} from '@trpc/client';
import {AppRouter} from 'api/entry';
import superjson from 'superjson';
import {getBaseUrl} from 'utils/helper/api';

export const client = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${getBaseUrl()}/api/trpc`,
      transformer: superjson,
    }),
  ],
});
