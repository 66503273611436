import type {StateCreator} from 'zustand';
import {CheckoutS} from 'schema/validation/entry';
import {DefaultSlice} from './defaultSlice';

export const checkoutSlice: StateCreator<
  DefaultSlice & CheckoutSlice,
  [
    ['zustand/immer', never],
    ['zustand/devtools', never],
    ['zustand/persist', unknown],
  ],
  [],
  CheckoutSlice
> = set => ({
  scs_state: [[], false],
  set_scs_state: scs_state =>
    set(state => {
      state.scs_state = [scs_state, true];
    }),
  setLocation: location =>
    set(state => {
      state.location = location;
    }),
});

export interface CheckoutSlice {
  location?: {
    state: string;
    city: string;
  };
  scs_state: [NonNullable<CheckoutS['scs']>, boolean];
  set_scs_state: (value: NonNullable<CheckoutS['scs']>) => void;
  setLocation: (payload: {state: string; city: string}) => void;
  // resetOrder: () => void;
}
