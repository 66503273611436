import {create} from 'zustand';
import {devtools, persist} from 'zustand/middleware';
import {immer} from 'zustand/middleware/immer';
import {checkoutSlice, CheckoutSlice} from './checkoutSlice';
import {DefaultSlice, defaultSlice} from './defaultSlice';
import {FeedSlice, feedSlice} from './feedSlice';

type CombinedState = DefaultSlice & FeedSlice & CheckoutSlice;

export const useStore = create<CombinedState>()(
  immer(
    persist(
      (...args) => ({
        ...checkoutSlice(...args),
        ...defaultSlice(...args),
        // ...feedSlice(...args),
      }),
      {
        name: 'delorand',
        partialize: state => ({
          location: state.location,
        }),
        onRehydrateStorage: () => (state, error) => {
          if (error) {
            if (state) state.hydrated = false;
          } else if (state) state.hydrated = true;
        },
        // getStorage: () => storage as StateStorage,
      }
    )
  )
);
