import {FeedWithRank} from 'api/helpers/feed';
import {CategoryGroup} from 'utils/helper/get-category-group';
import {StateCreator} from 'zustand';
import {CheckoutSlice} from './checkoutSlice';

export const defaultSlice: StateCreator<
  DefaultSlice & CheckoutSlice,
  [
    ['zustand/immer', never],
    ['zustand/devtools', never],
    ['zustand/persist', unknown],
  ],
  [],
  DefaultSlice
> = set => ({
  cartPath: 'cart',
  setCartPath: path =>
    set(state => {
      state.cartPath = path;
    }),
  categories: [],
  setCategories: categories => set({categories}),
  initialFeed: null,
  setInitialFeed: initialFeed => set({initialFeed}),
  auth: {modalState: false},
  hydrated: false,
  searchOpen: false,
  openSearch: () =>
    set(state => {
      state.searchOpen = true;
    }),
  closeSearch: () =>
    set(state => {
      state.searchOpen = false;
    }),
  onAfterAuth: payload => {
    set(state => {
      state.afterAuth = payload;
    });
  },
});

type ToastArgs = {
  title?: string;
  description?: string;
};

export type DefaultSlice = {
  initialFeed: FeedWithRank | null;
  hydrated: boolean;
  cartPath: string;
  setCartPath: (path: string) => void;
  searchOpen: boolean;
  openSearch: () => void;
  closeSearch: () => void;
  categories: CategoryGroup[];
  setCategories: (category: CategoryGroup[]) => void;
  setInitialFeed: (feed: FeedWithRank) => void;
  afterAuth?: () => void;
  onAfterAuth: (functionPayload: () => void) => void;
};
