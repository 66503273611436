'use client';

import type {ReactNode} from 'react';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import {CheckIcon} from '@heroicons/react/24/outline';
import {XMarkIcon} from '@heroicons/react/24/solid';
import {useStore} from 'c/store/store';
import {ThemeProvider} from 'next-themes';
import useSWR, {SWRConfig} from 'swr';
import {client} from '../server/api';

const Toaster = dynamic(() => import('ui/toast'), {ssr: false});

const RootClient = ({children}: {children: ReactNode}) => {
  const setCategories = useStore(store => store.setCategories);

  useSWR(['categories'], () => client.base.categories.query(), {
    onSuccess: data => setCategories(data),
    revalidateOnFocus: false,
    revalidateIfStale: false,
    errorRetryCount: 2,
    // revalidateOnMount: false,
  });

  const Search = dynamic(() => import('../components/search'));

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          classNames: {
            toast:
              'bg-black/80 backdrop-blur-md border-white/20 rounded-[14px] drop-shadow-sm top-[9vh] py-3 px-5',
            title: 'text-white ml-5',
            description: 'text-white/80',
            success: 'text-white',
            loader: 'text-white fill-white stroke-white',
            error: 'text-white/80',
            warning: 'text-white/70',
          },
        }}
        icons={{
          success: <CheckIcon className="size-5 stroke-2" />,
          error: <XMarkIcon className="size-5 stroke-2" />,
        }}
      />
      {process.env.NODE_ENV === 'production' && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_G_TAG_ID}`}
          />
          <Script id="google-analytics">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', '${process.env.NEXT_PUBLIC_G_TAG_ID}');
        `}
          </Script>
        </>
      )}
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          // revalidateOnMount: false,
          refreshWhenOffline: false,
          revalidateIfStale: false,
        }}
      >
        <ThemeProvider
          forcedTheme="dark"
          attribute="class"
          defaultTheme="dark"
          enableSystem={false}
        >
          {children}
        </ThemeProvider>
        <Search />
      </SWRConfig>
    </>
  );
};

export default RootClient;
